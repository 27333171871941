import * as React from "react";
import { IconProps } from "..";

const ONECYCLE = React.forwardRef<SVGSVGElement, IconProps>(
  ({ title = "ONECYCLE", titleId = "-o-n-e-c-y-c-l-e", ...props }, svgRef) => {
    return (
      <svg
        viewBox="0 0 284 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
        aria-labelledby={titleId}
        {...props}
      >
        {title ? <title id={titleId}>{title}</title> : null}
        <g clipPath="url(#prefix__clip0_7015_25486)" fill="currentColor">
          <path d="M0 20.26A20.39 20.39 0 0120.46.05c9.81 0 16.38 6.62 16.38 15.4a20.38 20.38 0 01-20.46 20.21C6.57 35.66 0 29 0 20.26zm27.13-4.07c0-4.37-2.65-7.61-7.51-7.61-6 0-9.91 5.84-9.91 10.94 0 4.37 2.65 7.61 7.51 7.61 6.03 0 9.91-5.84 9.91-10.94zM46.5.69h8.88l8.88 19.08L69.41.69h9.47L69.66 35h-8.34l-9.27-19.74L46.75 35h-9.47L46.5.69zM85.35.69h27.52l-2.2 8.09H92.61L91.19 14h16.39l-2 7.51h-16.4l-1.47 5.44h18.34L103.9 35H76.13L85.35.69zM112.58 20.7A21.003 21.003 0 01134 0c7.07 0 11.83 3.38 13.94 8.68l-8.24 4.42a7.175 7.175 0 00-6.92-4.42c-5.68 0-10.24 5.32-10.24 11.32 0 4.37 2.89 7.07 6.86 7.07 3 0 5.15-1.28 7.36-3.48l6.43 5.69a18.236 18.236 0 01-14.37 6.42c-9.52.01-16.24-5.82-16.24-15zM157.66 21.73l-6.92-21H161l3.39 12.85L174.73.69h11.38l-19.18 22.07L163.64 35h-9.56l3.58-13.27zM182.53 20.7a20.988 20.988 0 016.422-14.823A20.996 20.996 0 01204 0c7 0 11.79 3.38 13.9 8.68l-8.24 4.42a7.181 7.181 0 00-6.92-4.42c-5.69 0-10.25 5.35-10.25 11.33 0 4.37 2.89 7.07 6.86 7.07 3 0 5.15-1.28 7.36-3.48l6.43 5.69a18.257 18.257 0 01-14.38 6.42c-9.51 0-16.23-5.83-16.23-15.01zM224.86.69h9.57l-7 26h16.38L241.59 35h-26L224.86.69zM255.67.69h27.52L281 8.78h-18.07L261.5 14h16.39l-2 7.51h-16.4L258 26.93h18.35L274.21 35h-27.77L255.67.69z" />
        </g>
        <defs>
          <clipPath id="prefix__clip0_7015_25486">
            <path fill="currentColor" d="M0 0h283.19v35.71H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);
export default ONECYCLE;
